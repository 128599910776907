/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import { PROXY_WS_URLS } from "../utils/url";
import { Environment } from "../types";
import { useEnvironmentContext } from "./EnvironmentContext";
import { useAuthContext } from "./AuthContext";

export type WebsocketContext = {
  isConnected: boolean;
  connect: (env: Environment) => Promise<void>;
  disconnect: () => Promise<void>;
  subscribe: (channel: string, handler: any) => Promise<void>;
  unsubscribe: (channel: string, handler: any) => void;
};

export const WebsocketContext = React.createContext<WebsocketContext>({
  isConnected: false,
  connect: async (env: Environment) => {},
  disconnect: async () => {},
  subscribe: async (channel: string, handler: any) => {},
  unsubscribe: (channel: string, handler: any) => {},
});

export const useWebsocketContext = () => React.useContext(WebsocketContext);

let ws: WebSocket;
const channels: { [key: string]: any[] } = {};

export function WebsocketContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();
  const [isConnected, setIsConnected] = React.useState<boolean>(false);

  const connect = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      ws = new WebSocket(PROXY_WS_URLS[environment], ['Bearer', token ?? 'error']);

      ws.onopen = () => {
        setIsConnected(true);
        resolve();
        console.log("[ws] connected to env", environment);
      };

      ws.onmessage = (e: any) => {
        const action = JSON.parse(e.data);
        switch (action.type) {
          case "update": {
            if (!(action.channel in channels)) {
              return;
            }
            const subscribers = channels[action.channel];
            subscribers.forEach((callback: any) => {
              callback(action.payload);
            });
            break;
          }
          case 'subscribe_ack':
            // subscribe confirmation
            break;
          case 'unsubscribe_ack':
            // unsubscribe confirmation
            break;
          default: {
            console.log("[ws] received unknown action:", action);
            break;
          }
        }
      };

      ws.onerror = (err) => {
        reject(err);
      };
    });
  };

  const disconnect = async (): Promise<void> => {
    setIsConnected(false);
    ws.close();
    console.log("[ws] disconnected");
  };

  const send = (payload: any) => {
    if (!isConnected || ws.readyState !== WebSocket.OPEN) {
      return;
    }

    ws.send(JSON.stringify(payload));
  };

  const subscribe = async (channel: string, callback: any) => {
    if (!(channel in channels)) {
      channels[channel] = [];
    }
    channels[channel].push(callback);

    send({ type: "subscribe", payload: channel });
    console.log("[ws] subscribe", channel);
  };

  const unsubscribe = (channel: string, callback: any) => {
    if (!isConnected) {
      return;
    }

    if (!(channel in channels)) {
      return;
    }
    const idx = channels[channel].findIndex((cb) => cb === callback);
    channels[channel].splice(idx, 1);

    send({ type: "unsubscribe", payload: channel });
    console.log("[ws] unsubscribe", channel);
  };

  const handleConnect = async () => {
    if (ws) {
      await disconnect();
    }
    connect();
  };

  React.useEffect(() => {
    if (token) {
      handleConnect();
    }
  }, [token, environment]);

  return (
    <WebsocketContext.Provider
      value={{ isConnected, connect, disconnect, subscribe, unsubscribe }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}
