import { get } from "@rigly/core";
import { apiUrl, proxyUrl } from "../utils/url";
import { Environment } from "../types";

export class ApiError extends Error {
  public status: number;
  public detail: string;

  constructor(message: string, status: number, detail: string) {
    super(message); // Pass the message to the Error constructor
    this.name = 'ApiError'; // Custom name for the error class
    this.status = status; // Custom property to store the HTTP status code
    this.detail = detail; // Custom property to store a detailed error message
  }
}


interface Response {
  results: any[];
  count: number;
}

export async function getProxies(
  env: Environment,
  token: string
): Promise<Response> {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await get(proxyUrl(env, "/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxy/list"), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
}

export async function getProxyLogsArchive(
  proxyId: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${proxyId}/all_logs_as_zip`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.status == 200 || res.status == 206) {
    const archiveInfo = await res.json();
    
    // still opens error in new tab if unsuccessful...
    window.open(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/log_archives/${archiveInfo.archive_id}?filename=${archiveInfo.archive_filename}`), '_blank')
    
    return archiveInfo;
  }
 
}

export async function getProxyLogs(
  proxyId: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${proxyId}/logs`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const logs = await res.text();
  return logs;
}

export async function getProxy(
  proxyId: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await get(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxy/list/${proxyId}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
}

export async function switchProxy(
  proxyId: string,
  data: {
    upstream_host: string;
    upstream_port: number;
    pool_username: string;
    order_id: number;
  },
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${proxyId}/switch`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accepts: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  const payload = await res.json();
  if (payload.detail) {
    throw new Error(payload.detail);
  }

  return payload;
}

export async function createProxy(data: any, env: Environment, token: string) {
  if (!token) {
    throw new Error("Invalid token");
  }
  const res = await fetch(proxyUrl(env, "/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies"), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
    body: JSON.stringify(data),
  });

  const payload = await res.json();
  if (payload.detail) {
    throw new Error(payload.detail);
  }

  return payload;
}

export async function editProxy(proxyId: string, data: any, env: Environment, token: string) {
  if (!token) {
    throw new ApiError("Invalid token", 401, "");
  }

  console.log(`Sending request to edit proxy with ID ${proxyId}:`, data);

  try {
    const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${proxyId}`), {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      const errorBody = await res.json();
      console.error(`Failed to edit proxy with ID ${proxyId}. Status: ${res.status} ${res.statusText}`, errorBody);

      // Use the custom ApiError class to throw a detailed error
      throw new ApiError(`Failed to edit proxy with ID ${proxyId}. Status: ${res.status} ${res.statusText}`, res.status, errorBody.detail);
    }

    const payload = await res.json();
    console.log(`Successfully edited proxy with ID ${proxyId}:`, payload);
    return payload;
  } catch (error) {
    // Ensure any caught error is an instance of Error before rethrowing
    if (!(error instanceof Error)) {
      throw new ApiError('An unexpected error occurred during the proxy editing process.', 500, "");
    }
    throw error; // Rethrow if it's already an Error instance
  }
}






export async function deleteProxy(
  proxyId: number,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${proxyId}`), {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  });

  const payload = await res.json();

  return payload;
}

export async function getCreateProxySchema(env: Environment, token: string) {
  if (!token) {
    throw new Error("Invalid token");
  }
  // api/ops/proxies/create maps to ops.get_create_proxy_schema
  const res = await get(apiUrl(env, `/api/ops/proxies/create`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
}
export async function getHashrateChart(
  id: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${id}/hashrate`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  });

  const payload = await res.json();
  if (payload.detail) {
    throw new Error(payload.detail);
  }

  return payload;
}

export async function getDifficultyHistory(
  id: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${id}/difficulty`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  });

  const payload = await res.json();
  if (payload.detail) {
    throw new Error(payload.detail);
  }

  return payload;
}

export async function getAssignmentHistory(
  id: string,
  env: Environment,
  token: string
) {
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(proxyUrl(env, `/zrbSN2vn5AsJbmzEoqsy22gpFAPFh9zvNzeYdZd7wN9qvcAnd/api/v1/proxies/${id}/assignments`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  });

  const payload = await res.json();
  if (payload.detail) {
    throw new Error(payload.detail);
  }

  return payload;
}
