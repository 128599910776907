import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Modal from "../core/Modal";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { apiUrl } from "../../utils/url";
import { get } from "../../utils/fetch";
import { Order, OrderType, PaginationState } from "../../types";
import Pagination from "../core/Pagination";
import Loader from "../core/Loader";
import Toolbar from "../core/Toolbar";
import clsx from "clsx";
import Input from "../core/Input";

export default function SelectOrderModal({ value }: { value?: Order }) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["select-order"];

  const [selectedOrder, setSelectedOrder] = React.useState<Order | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [orders, setOrders] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadOrders = async (query?: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const searchParam = !query || query === "" ? "" : `&search=${query}`;
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/orders?limit=${limit}&offset=${offset}${searchParam}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(res.results);
      setTotal(res.count);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  const handleCancel = () => {
    meta.onCancel();
    setSelectedOrder(undefined);
    hideModal("select-order");
  };

  const handleConfirm = () => {
    meta.onConfirm(selectedOrder);
    setSelectedOrder(undefined);
  };

  const handleSelect = (order: Order) => {
    setSelectedOrder(order);
  };

  React.useEffect(() => {
    loadOrders(search);
    setSelectedOrder(undefined);
  }, [limit, offset, environment, token, search]);

  React.useEffect(() => {
    setSelectedOrder(value);
  }, [value]);

  return (
    <Modal
      active={active}
      onClose={handleCancel}
      className="w-[calc(100%_-_4rem)] xl:w-4/5"
    >
      <Modal.Header>
        <Modal.Title>Select Order</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="flex flex-col">
          <Toolbar>
            <Input
              onChange={(val: string) => setSearch(val)}
              id="orderSearch"
              type="text"
              placeholder="Search order"
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>
          <table className="h-[352px]">
            <thead>
              <tr className="h-8 border-b border-white/10">
                <th className="pl-3 text-left text-white text-xs">Selected</th>
                <th className="text-left text-white text-xs">Order</th>
                <th className="text-left text-white text-xs">Auction</th>
                <th className="text-left text-white text-xs">Status</th>
                <th className="text-left text-white text-xs">Account</th>
                <th className="pr-3 text-left text-white text-xs">Pool user</th>
              </tr>
            </thead>
            {loading && (
              <tbody>
                <tr>
                  <td className="" colSpan={6}>
                    <Loader className="mx-auto w-6 h-6 text-white" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && orders.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={6} className="text-center text-white/50 text-sm">
                    No result(s).
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && orders.length > 0 && (
              <tbody>
                {orders.map((order: Order, i: number) => {
                  return (
                    <tr
                      key={i}
                      className={clsx(
                        "h-8 hover:bg-white/5 border-b border-white/10 last-of-type:border-0 cursor-pointer",
                        {
                          "bg-white/5": order.id === selectedOrder?.id,
                        }
                      )}
                      onClick={() => handleSelect(order)}
                    >
                      <td className="pl-3 text-white text-sm whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={order.id === selectedOrder?.id}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onChange={() => {}}
                        />
                      </td>
                      <td className="pr-3 text-white text-sm whitespace-nowrap">
                        Order {order.id}
                      </td>
                      {order.type === OrderType.BlockParty ? (
                        <td className="pr-3 text-white text-sm whitespace-nowrap">
                          {order.block_party!.name} ({order.block_party!.id})
                        </td>
                      ) : order.type === OrderType.Direct ? (
                        <td className="pr-3 text-white text-sm whitespace-nowrap">
                          Direct {order.id}
                        </td>
                      ) : (
                        <td className="pr-3 text-white text-sm whitespace-nowrap">
                          {order.auction?.title || '-'} {order.auction?.id ? `(${order.auction.id})` : ''}
                        </td>
                      )}{" "}
                      <td className="pr-3 text-white text-sm whitespace-nowrap">
                        {order.status}
                      </td>
                      <td className="pr-3 text-white text-sm whitespace-nowrap">
                        {order.account.email}
                      </td>
                      <td className="pr-3 text-white text-sm whitespace-nowrap">
                        {order.account.pool_user
                          ? `${
                              order.account.pool_user.username
                            }@${order.account.pool_user.pool.replace(
                              "stratum+tcp://",
                              ""
                            )}`
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="blue"
          onClick={handleConfirm}
        >
          Select Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
